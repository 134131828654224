<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3 register">
      <v-container class="fill-height ">
        <v-row align="center" justify="center" no-gutters class="d-flex justify-center" ref="formCard">
          <v-col md="5" lg="3">
            <v-sheet elevation="10" color="transparent">
              <v-img :src="cardimg" fill-height height="500" class="rounded-l"></v-img>
            </v-sheet>
          </v-col>
          <!-- register form -->
          <v-col md="5" :lg="registered ? 5 : 3">
            <v-card v-if="!registered" elevation="10" tile class="rounded-r" height="500" :loading="loading">
              <v-card-title style="color: red;">
                ระบบลงทะเบียนขัดข้อง <br/>ไม่สามารถลงทะเบียนใหม่ได้ในขณะนี้
              </v-card-title>
              <v-container>
                <v-alert type="error" v-if="errorMessage != ''">
                  {{ errorMessage }}
                </v-alert>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    maxlength="13"
                    hint="enter รหัสบัตรประชาชน 13 หลัก"
                    label="Username (รหัสบัตรประชาชน 13 หลัก)"
                    required
                    :name="Math.random()"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="password"
                    type="password"
                    :rules="passwordRules"
                    hint="enter your password"
                    label="Password (รหัสผ่าน)"
                    :name="Math.random()"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    hint="กรอกเบอร์โทรศัพท์มือถือ"
                    label="เบอร์โทรศัพท์"
                    required
                  ></v-text-field>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-btn text color="indigo darken-4" dark to="login">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                  ยกเลิก
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="indigo darken-4" dark :loading="loading" @click="register">
                  ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else elevation="10" height="500" max-height="500" tile class="mx-auto rounded-r" scrollable>
              <v-container>
                <v-alert type="success">
                  ลงทะเบียนและยืนยันการเข้าใช้งานเรียบร้อยแล้ว
                </v-alert>
                <v-list>
                  <v-list-item v-for="(item, i) in userInfo" :key="i">
                    <v-list-item-content>
                      <v-list-item-subtitle>{{ item.t }}</v-list-item-subtitle>
                      <v-list-item-title>{{ item.v }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="indigo darken-4" dark to="/login">
                  <v-icon>
                    mdi-login
                  </v-icon>
                  กลับไป Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import cardimg from '@/assets/register-banner.png'
import { register } from '@/api/auth'
export default {
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const data = {
          username: this.username,
          password: this.password,
          phone: this.phone,
        }
        // this.registered = true
        register(data)
          .then(res => {
            // console.log(res)
            if (res.cid) {
              this.registered = true
              this.fetchInfo(res)
            }
          })
          .catch(err => {
            this.errorMessage = err.response?.data?.error || 'พบข้อผิดพลาด'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    fetchInfo(obj) {
      const arr = []
      for (const [key, v] of Object.entries(obj)) {
        // console.log(`${key}: ${v}`)
        arr.push(v)
      }
      // console.log(arr)
      this.userInfo = arr
    },
  },
  watch: {
    registered() {
      // console.log(this.$refs.cH.clientHeight)
    },
  },
  data() {
    return {
      valid: false,
      cardimg: cardimg,
      registered: false,
      userInfo: [],
      cardHeight: 0,
      username: '',
      usernameRules: [v => !!v || 'กรอก Username เลขบัตรผปชชน 13 หลัก'],
      password: '',
      passwordRules: [v => !!v || 'กรอก Password'],
      phone: '',
      phoneRules: [v => /\d{10}/.test(v) || 'กรอกเฉพาะตัวเลขจำนวน 10 หลักเท่านั้น'],
      loading: false,
      errorMessage: '',
    }
  },
}
</script>

<style scope>
.register {
  background-image: url('../../assets/tpcc-register-bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
